var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("Step 2.")]),_vm._v(" "),_c('i',[_vm._v("Isolating the unknown acid.")])]),_c('p',[_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',[_vm._v("(image placeholder)")]),_c('p',[_vm._v(" Flask from "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',[_vm._v("-> swirl")]),_c('p',[_vm._v(" Check the "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" . ")],1),_c('p',[_vm._v("The solution will soon become cloudy.")]),_c('p',[_vm._v(" -> After adding "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}}),_vm._v(" of HCl, place the flask in a: (image) ")],1),_c('p',[_vm._v("The acid will precipiatte")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }